import React, { Component } from "react"
import "./Style.scss"

class NoMatch extends Component {
  render() {
    return (
      <div className="notMatch">
        <h3 className="notMatchText">صفحه مورد نظر وجود ندارد :(</h3>
      </div>
    )
  }
}

export default NoMatch
