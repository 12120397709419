import React from "react"
import "./Style.scss"
import { Link } from "react-router-dom"

class CollegeCard extends React.Component {
  render() {
    return (
      <Link className="college_card" to={`/college/${this.props.imgUrl}/`}>
        <div className="col-md-4 col-sm-6">
          <div>
            <img
              alt={this.props.collegeName}
              src={require("../../assets/img/" +
                `${this.props.imgUrl}` +
                ".png")}
            />
            <span>{this.props.collegeName}</span>
            <img
              alt={this.props.collegeName}
              src={require("../../assets/img/front.png")}
            />
          </div>
        </div>
      </Link>
    )
  }
}
export default CollegeCard
