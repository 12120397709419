import React, { Component } from "react"
import "./Style.scss"
import { AXIOS } from "../../utilities"

export class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      commentCounter: 0,
    }
  }

  componentDidMount() {
    AXIOS.get(`stats/`)
      .then(({ data }) => {
        console.log(data)
        this.setState({
          commentCounter: data.comment_total_count,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    return (
      <footer className="footer container">
        <div className="row">
          <div className="col-lg-4 col-sm-9 col-xs-8">
            <div className="counter">
              <span>{this.state.commentCounter}</span>
              <span>نظر تا این لحظه</span>
            </div>
          </div>
          <div className="col-lg-7 col-sm-12">
            <div className="about-us">
              <h3>درباره ما</h3>
              <p>
                در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه
                راهکارها و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل
                حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود
                طراحی اساسا مورد استفاده قرار گیرد.
              </p>
            </div>
            {/* <hr className="bottom-line"></hr> */}
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
