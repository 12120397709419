import React, { Component } from "react"
import "./Style.scss"
import Slider from "react-slick"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"
import { FiSearch, MdPersonOutline } from "react-icons/all"
import { Link } from "react-router-dom"
import { Button } from "antd"
import { Footer } from "./Footer"
import { AXIOS } from "../../utilities"
import { TeacherCard, CollegeCard } from "../../components"

export class HomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      teachers: [],
    }
  }

  componentDidMount() {
    AXIOS.get(`teachers/?ordering=-comment_count`)
      .then(({ data }) => {
        this.setState({
          teachers: data.results,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    const isMobile = () => {
      if (window.innerWidth >= 320 && window.innerWidth <= 768) {
        return 1
      }
      if (window.innerWidth > 738 && window.innerWidth <= 992) {
        return 2
      }
      return 3
    }
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: isMobile(),
      slidesToScroll: 1,
    }
    return (
      <div className="home">
        <div className="hero">
          {/* <Row> */}
          <div className="container">
            <div className=" col-md-6 col-sm-9 right">
              <h2>استادسنج</h2>
              <p>
                پایگاهی <mark> جامع </mark> برای دانشجویان سجاد، محلی برای
                انتخاب واحد <mark> آسان </mark> و با ریسکی <mark>پایین</mark>{" "}
              </p>
              <div className="buttons">
                <div className="">
                  <Button>
                    <Link>جستجو</Link>
                    <FiSearch />
                  </Button>
                </div>
                <div className="">
                  <Button>
                    <Link to="/auth/signup">ورود / ثبت‌نام</Link>
                    <MdPersonOutline />
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-3 img">
              <img
                src={require("../../assets/img/prof-img.png")}
                alt="استاد سنج"
              />
            </div>
          </div>
          {/* </Row> */}
        </div>
        <div className="container colleges">
          <div className="">
            <h3 className="title">دانشکده‌ها</h3>
          </div>
          <div className="collegeImg">
            <CollegeCard
              imgUrl="memari"
              collegeName="عمران، معماری و شهرسازی"
            />
            <CollegeCard
              imgUrl="computer"
              collegeName="کامپیوتر و فناوری اطلاعات"
            />
            <CollegeCard imgUrl="sanaee" collegeName="صنایع و مدیریت" />
            <CollegeCard imgUrl="omomi" collegeName="دروس عمومی ومعارف" />
            <CollegeCard imgUrl="pezeshki" collegeName="برق و مهندسی پزشکی" />
            <CollegeCard imgUrl="mekanic" collegeName="مکانیک و مواد" />
          </div>
        </div>

        <div className="bestOf container">
          <div className="row justify-content-center">
            <h3 className="title">اساتید برتر استادسنج</h3>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider
                {...settings}
                nextArrow={<FaAngleRight />}
                prevArrow={<FaAngleLeft />}
              >
                {this.state.teachers.map((item) => (
                  <TeacherCard
                    key={item.id}
                    name={item.name}
                    image={item.photo}
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
