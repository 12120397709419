import React from "react"
import { Link } from "react-router-dom"
import { Progress, Tabs, Slider } from "antd"
import { CommentCard } from "../../components"
import "./Style.scss"

export class TeacherProfile extends React.Component {
  handleChange = (activeKey) => {
    this.setState({ activeKey })
    activeKey === "3"
      ? this.setState({
          tooltipVisiblity: true,
        })
      : this.setState({
          tooltipVisiblity: false,
        })
  }
  constructor(props) {
    super(props)
    this.state = {
      tooltipVisiblity: false,
      activeKey: "1",
    }
  }
  render() {
    const { TabPane } = Tabs
    const score = 50

    return (
      <div className="TeacherPage">
        <div className="container">
          <div className="TeacherPage__top">
            <div className="TeacherPage__top__name">
              <span>مهندس یونجه خور</span>
              <span>(عضو هیئت علمی)</span>
            </div>
            <div className="TeacherPage__top__info">
              <div className="col-lg-4 col-sm-12 imgInfo">
                <img src={require("../../assets/img/cow.jpg")} alt="استادسنج" />
              </div>
              <div className="col-lg-8 col-sm-12 rateInfo">
                <div className="rateInfo__rate">
                  <div>
                    <span className="rateInfo__rate__title">کیفیت تدریس</span>
                    <div className="rateInfo__rate__score">
                      <span>۱۰</span>
                      <Progress percent={score} />
                      <span
                        className="rateInfo__rate__score__pointer"
                        style={{ right: `${100 - score}%` }}
                      >
                        {score / 10}
                      </span>
                      <span>۰</span>
                    </div>
                  </div>
                  <div>
                    <span className="rateInfo__rate__title">
                      اهمیت حضور غیاب
                    </span>
                    <div className="rateInfo__rate__score">
                      <span>۱۰</span>
                      <Progress percent={score} />
                      <span
                        className="rateInfo__rate__score__pointer"
                        style={{ right: `${100 - score}%` }}
                      >
                        {score / 10}
                      </span>
                      <span>۰</span>
                    </div>
                  </div>
                  <div>
                    <span className="rateInfo__rate__title">
                      وضعیت نمره دهی
                    </span>
                    <div className="rateInfo__rate__score">
                      <span>۱۰</span>
                      <Progress percent={score} />
                      <span
                        className="rateInfo__rate__score__pointer"
                        style={{ right: `${100 - score}%` }}
                      >
                        {score / 10}
                      </span>
                      <span>۰</span>
                    </div>
                  </div>
                </div>
                <div className="rateInfo__buttons">
                  <Link>ویرایش اطلاعات</Link>
                  <Link>آپلود جزوه استاد</Link>
                  <Link>بیوگرافی</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="TeacherPage__bottom">
            <Tabs
              type="card"
              onChange={this.handleChange}
              activeKey={this.state.activeKey}
            >
              <TabPane tab="دروس ارائه شده ترم" key="1">
                <div className="lessonsTab">
                  <p>برای دانلود جزوه هر درس روی آن کلیک کنید</p>
                  <div className="col-md-6 col-sm-12">
                    <Link>- ساختمان داده</Link>
                    <Link>- ساختمان داده</Link>
                    <Link>- ساختمان داده</Link>
                    <Link>- شیوه ارائه مطالب علمی و فنی</Link>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <Link>- ساختمان داده</Link>
                    <Link>- ساختمان داده</Link>
                    <Link>- ساختمان داده</Link>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="نظرات" key="2">
                <CommentCard title="ممد">
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                  استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و
                  مجله در ستتون و سطرآنچنان که لازم است و برای شرایط فعلی
                  تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                  کاربردی می باشد. کتابهای زیادی در شصــت و سه درصد گذشته، حال و
                  آینـده شناخت فراوان جامعــه و متخصصــان را می طلبد تا با نرم
                  افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصـــوص
                  طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در ایـن
                  صورت می تـوان امید داشت که تمـام و دشواری موجود در ارائه
                  راهکارها و شرایط سخــت تایپ به پایان رسد وزمان مورد نیاز شامل
                  حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای
                  موجود طراحی اساسا مورد استفاده قرار گیرد.
                </CommentCard>
                <CommentCard title="علی اسکندری">
                  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                  استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و
                  مجله در ستتون و سطرآنچنان که لازم است و برای شرایط فعلی
                  تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                  کاربردی می باشد. کتابهای زیادی در شصــت و سه درصد گذشته، حال و
                  آینـده شناخت فراوان جامعــه و متخصصــان را می طلبد تا با نرم
                  افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصـــوص
                  طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد.
                </CommentCard>
              </TabPane>
              <TabPane tab="ثبت نظر" key="3">
                <div className="saveRateTab">
                  <CommentCard>
                    <h3 className="saveRateTab__title">امتیازدهی</h3>
                    <div className="saveRateTab__rate">
                      <div>
                        <span className="saveRateTab__rate__title">
                          کیفیت تدریس
                        </span>
                        <div className="saveRateTab__rate__slider">
                          <span>۱۰</span>
                          <div>
                            <Slider
                              className="slider"
                              min={0}
                              max={10}
                              tooltipVisible={this.state.tooltipVisiblity}
                              reverse
                            />
                          </div>
                          <span>۰</span>
                        </div>
                      </div>
                      <div>
                        <span className="saveRateTab__rate__title">
                          اهمیت حضور غیاب
                        </span>
                        <div className="saveRateTab__rate__slider">
                          <span>۱۰</span>
                          <div>
                            <Slider
                              className="slider"
                              min={0}
                              max={10}
                              tooltipVisible={this.state.tooltipVisiblity}
                              reverse
                            />
                          </div>
                          <span>۰</span>
                        </div>
                      </div>
                      <div>
                        <span className="saveRateTab__rate__title">
                          وضعیت نمره دهی
                        </span>
                        <div className="saveRateTab__rate__slider">
                          <span>۱۰</span>
                          <div>
                            <Slider
                              className="slider"
                              min={0}
                              max={10}
                              tooltipVisible={this.state.tooltipVisiblity}
                              reverse
                            />
                          </div>
                          <span>۰</span>
                        </div>
                      </div>
                    </div>
                    <div className="sendButton">
                      <button>ثبت</button>
                    </div>
                  </CommentCard>
                  <form className="saveRateTab__form">
                    <div>
                      <input
                        placeholder="نام"
                        className="saveRateTab__form__style"
                      />
                      <input
                        placeholder="ایمیل"
                        type="email"
                        className="saveRateTab__form__style"
                      />
                    </div>
                    <textarea
                      className="saveRateTab__form__style"
                      placeholder="متن نظر"
                    />
                    <div className="sendButton">
                      <button type="submit">ارسال</button>
                    </div>
                  </form>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    )
  }
}
