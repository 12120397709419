import React from "react"
import "./Style.scss"

export class CommentCard extends React.Component {
  render() {
    return (
      <div className="card">
        <div className="card__title">{this.props.title}</div>
        <div className="card__body">{this.props.children}</div>
      </div>
    )
  }
}
