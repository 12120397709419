import React, { Component } from "react"
import "../Style.scss"
import { FaChevronLeft } from "react-icons/fa"
import { Link } from "react-router-dom"
import { Form, Input, message, Button } from "antd"
import { AXIOS } from "../../../utilities"

export class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      btnEnable: 1,
      btnLoading: 0,
    }
  }
  
  success = (text) => {
    message.success(text)
  }

  error = (text) => {
    message.error(text)
  }

  warning = (text) => {
    message.warning(text)
  }

  handleServerError = (data) => {
    if (data.detail) {
      this.error("اطلاعات اشتباه است")
    }
  }

  onFinish = (values) => {
    this.setState({
      btnLoading: 1,
    })
    AXIOS.post("accounts/login/", values)
      .then((res) => {
        this.setState({
          btnEnable: 0,
          btnLoading: 0,
        })
        console.log(res)
        // TODO: https://www.taniarascia.com/full-stack-cookies-localstorage-react-express/
        localStorage.setItem("_token", res.data.token)
        this.success("ورود با موفقیت انجام شد")
      })
      .catch((err) => {
        this.setState({
          btnLoading: 0,
        })
        this.handleServerError(err.response.data)
      })
  }

  render() {
    return (
      <div className="submitForm">
        <h3 className="submitForm__title">ورود به حساب کاربری</h3>
        <Form name="basic" onFinish={this.onFinish} autoComplete="off">
          <Form.Item
            name="login"
            validateTrigger={["onBlur"]}
            rules={[
              { required: true, message: "لطفا ایمیل رو وارد کن" },
              { type: "email", message: "لطفا یک ایمیل معتبر وارد کنید." },
            ]}
          >
            <Input
              dir="auto"
              placeholder="ایمیل شما"
              className="submitForm__input"
            />
          </Form.Item>

          <Form.Item
            name="password"
            validateTrigger={["onBlur"]}
            rules={[
              {
                required: true,
                message: "لطفا رمز عبور خود را وارد نمایید",
              },
              {
                type: "string",
                min: 8,
                max: 500,
                message: "حداقل ۸ کاراکتر وارد نمایید",
              },
            ]}
          >
            <Input
              dir="auto"
              type="password"
              placeholder="رمز عبور"
              className="submitForm__input"
            />
          </Form.Item>

          <Form.Item>
            <Button
              disabled={!this.state.btnEnable}
              loading={this.state.btnLoading}
              className="submitForm__button disable"
              htmlType="submit"
            >
              ورود
            </Button>
          </Form.Item>
        </Form>

        <div className="submitForm__link">
          <FaChevronLeft />
          <Link to="/auth/signup">ثبت نام در استادسنج</Link>
        </div>
        <div className="submitForm__link">
          <FaChevronLeft />
          <Link to="/auth/forget-password/">
            رمز عبور خود را فراموش کرده اید؟
          </Link>
        </div>
      </div>
    )
  }
}
