import React from "react"
import { Switch, Route } from "react-router-dom"
import { TeacherProfile } from "../pages/TeacherProfile"
import { NoMatch } from "../components"
import { Auth } from "../pages/AuthPages"
import { HomePage } from "../pages/Home"
import { CollegeTeachersList } from "../pages/CollegeTeachersList"

export const MainRouter = () => (
  <Switch>
    <Route exact path="/" component={HomePage} />
    <Route path="/auth" component={Auth} />
    <Route
      exact
      path="/college/sanaee"
      render={() => <CollegeTeachersList name="صنایع و مدیریت" id="6" />}
    />
    <Route
      exact
      path="/college/memari"
      render={() => (
        <CollegeTeachersList name="عمران، معماری و شهرسازی" id="2" />
      )}
    />
    <Route
      exact
      path="/college/mekanic"
      render={() => <CollegeTeachersList name="مکانیک و مواد" id="3" />}
    />
    <Route
      exact
      path="/college/pezeshki"
      render={() => <CollegeTeachersList name="برق و مهندسی پزشکی" id="4" />}
    />
    <Route
      exact
      path="/college/omomi"
      render={() => <CollegeTeachersList name="دروس عمومی" id="5" />}
    />
    <Route
      exact
      path="/college/computer"
      render={() => (
        <CollegeTeachersList name="کامپیوتر و فناوری اطلاعات" id="1" />
      )}
    />
    <Route exact path="/teacher" component={TeacherProfile} />
    <Route component={NoMatch} />
  </Switch>
)
