import React from "react"
import "./Style.scss"
// import { Link } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"
import Slider from "react-slick"
import { TeacherCard } from "../../components"
import { AXIOS } from "../../utilities"
import { Button } from "antd"

export class CollegeTeachersList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      teachers: [],
      nextLink: null,
      limit: 3,
      showLoadMoreBTN: true,
    }
  }

  componentDidMount() {
    AXIOS.get(`teachers/?college=${this.props.id}&limit=${this.state.limit}`)
      .then(({ data }) => {
        this.setState({
          teachers: data.results,
          nextLink: data.next.replace("http", "https"),
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  loadMore = () => {
    console.log(this.state.nextLink)

    AXIOS.get(this.state.nextLink)
      .then(({ data }) => {
        if (data.next !== null) {
          this.setState({
            teachers: [...this.state.teachers, ...data.results],
            nextLink: data.next.replace("http", "https"),
            showLoadMoreBTN: true,
          })
        } else {
          this.setState({
            teachers: [...this.state.teachers, ...data.results],
            nextLink: null,
            showLoadMoreBTN: false,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    const isMobile = () => {
      if (window.innerWidth >= 320 && window.innerWidth <= 768) {
        return 1
      }
      if (window.innerWidth > 738 && window.innerWidth <= 992) {
        return 2
      }
      return 3
    }
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: isMobile(),
      slidesToScroll: 1,
    }
    return (
      <div className="universe">
        <div className="container">
          <div className="universe__top">
            <h3>{this.props.name}</h3>
            <h4>لیست اساتید دانشکده</h4>
            <div className="universe__top__cards">
              {this.state.teachers.map((item) => (
                <div className="col-lg-4 col-md-6" key={item.id}>
                  <TeacherCard
                    key={item.id}
                    name={item.name}
                    image={item.photo}
                  />
                </div>
              ))}
            </div>
            <div className="universe__top__showMore">
              <Button
                onClick={this.loadMore}
                disabled={!this.state.showLoadMoreBTN}
              >
                مشاهده بیشتر
              </Button>
            </div>
          </div>
          <div className="universe__bottom">
            <h3 className="title">اساتید برتر این دانشکده از نگاه شما</h3>
            <div className="row">
              <div className="col-md-12">
                <Slider
                  {...settings}
                  nextArrow={<FaAngleRight />}
                  prevArrow={<FaAngleLeft />}
                >
                  {this.state.teachers.map((item, index) => (
                    <TeacherCard
                      key={item.id}
                      name={item.name}
                      image={item.photo}
                    />
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
