import React from "react"
import "../Style.scss"
import { FaChevronLeft, FaStar } from "react-icons/fa"
import { Link } from "react-router-dom"
import { Form, Input, message, Button } from "antd"
import { AXIOS } from "../../../utilities"

export class SignupPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      btnEnable: 1,
      btnLoading: 0,
    }
  }

  success = (text) => {
    message.success(text)
  }

  error = (text) => {
    message.error(text)
  }

  warning = (text) => {
    message.warning(text)
  }

  handleServerError = (data) => {
    if (data.email) {
      this.error("ایمیل تکراری است")
    } else if (data.password) {
      this.error("رمز قوی‌تری انتخاب کنید (ترکیبی از عدد و حرف)")
    }
  }

  onFinish = (values) => {
    this.setState({
      btnLoading: 1,
    })
    AXIOS.post("accounts/register/", values)
      .then((res) => {
        this.setState({
          btnEnable: 0,
          btnLoading: 0,
        })
        this.success("ثبت نام با موفقیت انجام شد")
      })
      .catch((err) => {
        this.setState({
          btnLoading: 0,
        })
        this.handleServerError(err.response.data)
      })
  }

  render() {
    return (
      <div className="submitForm">
        <h3 className="submitForm__title">ساخت حساب کاربری</h3>

        <Form name="basic" onFinish={this.onFinish} autoComplete="off">
          <Form.Item
            name="email"
            validateTrigger={["onBlur"]}
            rules={[
              { required: true, message: "لطفا ایمیل رو وارد کن" },
              { type: "email", message: "لطفا یک ایمیل معتبر وارد کنید." },
            ]}
          >
            <Input
              dir="auto"
              placeholder="ایمیل شما"
              className="submitForm__input"
            />
          </Form.Item>

          <Form.Item
            name="password"
            validateTrigger={["onBlur"]}
            rules={[
              {
                required: true,
                message: "لطفا رمز عبور خود را وارد نمایید",
              },
              {
                type: "string",
                min: 8,
                max: 500,
                message: "حداقل ۸ کاراکتر وارد نمایید",
              },
            ]}
          >
            <Input
              dir="auto"
              type="password"
              placeholder="رمز عبور"
              className="submitForm__input"
            />
          </Form.Item>

          <Form.Item
            name="password_confirm"
            validateTrigger={["onBlur"]}
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "لطفا رمز عبور خود را مجددا وارد نمایید",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject("رمزعبور وارد شده یکسان نیست!")
                },
              }),
            ]}
          >
            <Input
              dir="auto"
              type="password"
              placeholder="تکرار رمز عبور"
              className="submitForm__input"
            />
          </Form.Item>

          <Form.Item>
            <Button
              disabled={!this.state.btnEnable}
              loading={this.state.btnLoading}
              className="submitForm__button disable"
              htmlType="submit"
            >
              ثبت نام
            </Button>
          </Form.Item>
        </Form>

        <div className="submitForm__desc">
          <FaStar />
          <p>
            ثبت نام در استادسنج به منزله پذیرش <mark>قوانین و مقررات </mark>سایت
            می باشد.
          </p>
        </div>
        <div className="submitForm__link">
          <FaChevronLeft />
          <Link to="/auth/login/">ورود به سایت</Link>
        </div>
      </div>
    )
  }
}
