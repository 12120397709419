import React from "react"
import { Switch, Route } from "react-router-dom"
import { LoginPage, SignupPage, ForgetPass } from "../pages/AuthPages"

export const AuthRouter = () => (
  <Switch>
    <Route exact path="/auth/login/" component={LoginPage} />
    <Route exact path="/auth/signup/" component={SignupPage} />
    <Route exact path="/auth/forget-password/" component={ForgetPass} />
  </Switch>
)
