import React, { Component } from "react"
import { Link } from "react-router-dom"
import { FaAngleLeft, FaCrown } from "react-icons/fa"
import "./Style.scss"

class TeacherCard extends Component {
  render() {
    return (
      <div className="ProfCard col-12">
        <div className="inner ">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-xs-6 pic">
                {this.props.crown ? (
                  <FaCrown className={`Crown ${this.props.crown}`} />
                ) : (
                  <></>
                )}
                <img src={this.props.image} alt={this.props.name} />
              </div>
              <div className="col-md-6 col-sm-6 col-xs-6 desc">
                <h5>{this.props.name}</h5>

                <div className="item">
                  <p>حضور و غیاب</p>
                  <p>
                    {this.props.present ||
                      Math.floor(Math.random() * Math.floor(10))}
                    /10{" "}
                  </p>
                </div>

                <div className="item">
                  <p>کیفیت تدریس</p>
                  <p>
                    {this.props.quality ||
                      Math.floor(Math.random() * Math.floor(10))}
                    /10{" "}
                  </p>
                </div>

                <div className="item">
                  <p>نمره دهی</p>
                  <p>
                    {this.props.score ||
                      Math.floor(Math.random() * Math.floor(10))}
                    /10{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row__bottom">
              <Link className="link" to="/teacher">
                بیشتر
                <FaAngleLeft className="icon" />
              </Link>
            </div>
          </div>
        </div>
        <hr className="bottom-line" />
      </div>
    )
  }
}

export default TeacherCard
