import React, { Component } from "react"
import { Link } from "react-router-dom"
import "./Style.scss"
import { Drawer } from "antd"
import { MdFormatAlignLeft } from "react-icons/all"

class Header extends Component {
  state = { visible: false }

  showDrawer = () => {
    this.setState({
      visible: true,
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }

  render() {
    const HeaderIsMobile = () => {
      if (window.innerWidth >= 320 && window.innerWidth <= 900) {
        return true
      }
      return false
    }
    return (
      <header className="header">
        <div className="container">
          <div className="row">
            <div className="col-md-1">
              <div className="logo">
                <img src={require("../../assets/img/logo-w.png")} alt="" />
              </div>
            </div>
            <div>
              {!HeaderIsMobile() ? (
                <>
                  <div className="col-md-11">
                    <ul className="menu">
                      <li>
                        {" "}
                        <Link className="active" to="/">
                          صفحه اصلی
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="/colleges"> دانشکده ها</Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="/prof-list"> لیست اساتید</Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="/book-list"> کتابدونی</Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="/contact-us">ارتباط با ما</Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="/rules">قوانین و مقررات</Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="/about-us"> درباره ما</Link>{" "}
                      </li>
                    </ul>
                    <div className="left">
                      <Link>جستجو</Link>
                      <Link>عضویت</Link>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-sm-1">
                  <div onClick={this.showDrawer}>
                    <MdFormatAlignLeft />
                  </div>
                  <Drawer
                    title="Basic Drawer"
                    placement="left"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    getContainer={false}
                    className="drawer"
                  >
                    <ul className="menu__drawer">
                      <li>
                        {" "}
                        <Link className="active" to="/">
                          صفحه اصلی
                        </Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="/colleges"> دانشکده ها</Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="/prof-list"> لیست اساتید</Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="/book-list"> کتابدونی</Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="/contact-us">ارتباط با ما</Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="/rules">قوانین و مقررات</Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="/about-us"> درباره ما</Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="/">جستجو</Link>{" "}
                      </li>
                      <li>
                        {" "}
                        <Link to="/auth/signup">عضویت</Link>{" "}
                      </li>
                    </ul>
                  </Drawer>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    )
  }
}
export default Header
