import React from "react"
import "../Style.scss"
import { FaChevronLeft, FaStar } from "react-icons/fa"
import { Link } from "react-router-dom"

class ForgetPass extends React.Component {
  render() {
    return (
      <div className="submitForm">
        <h3 className="submitForm__title">فراموشی رمز عبور</h3>
        <form>
          <input className="submitForm__input" placeholder="ایمیل:" />
          <button className="submitForm__button" type="submit">
            ارسال
          </button>
        </form>
        <div className="submitForm__desc">
          <FaStar />
          <p>بعد از ثبت درخواست لینک تعویض رمز به ایمیل شما ارسال می گردد.</p>
        </div>
        <div className="submitForm__link">
          <FaChevronLeft />
          <Link to="/auth/login/">ورود به سایت</Link>
        </div>
      </div>
    )
  }
}

export default ForgetPass
