const primary = "#19242d"
const dark = "#0d1118"
const secondary = "#51b6af"
const light = "#fff"
const blue = "#273343"
const danger = "#ecd345"

export const COLORS = {
  primary,
  dark,
  secondary,
  light,
  blue,
  danger,
}
