import React, { Component } from "react"
import { MainRouter } from "./routers"
import "antd/dist/antd.css"
import "./App.scss"
import { Header } from "./components"
import { BackToTop } from "./components"
import { HashRouter } from "react-router-dom"
import { ConfigProvider } from "antd"
import { ANT_CONFIG } from "./config"

class App extends Component {
  render() {
    return (
      <ConfigProvider {...ANT_CONFIG}>
        <div id="main" className="mainApp">
          <HashRouter>
            <div className="inner">
              <Header />
              <MainRouter />
            </div>
          </HashRouter>
        </div>
        <BackToTop />
      </ConfigProvider>
    )
  }
}

export default App
