import React from "react"
import { AuthRouter } from "../../routers/AuthRouter"
import "./Style.scss"

export class Auth extends React.Component {
  render() {
    return (
      <div className="AuthPage">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-4">
              <div className="img col-md-12">
                <img
                  src={require("../../assets/img/prof-img.png")}
                  alt="استاد سنج"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-8 col-sm-12">
              <AuthRouter />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
