import React, { Component } from "react"
import { FaAngleUp } from "react-icons/fa"
import "./Style.scss"

class BackToTop extends Component {
  render() {
    return (
      <div className="back-to-top">
        <a href="#top">
          <FaAngleUp className="icon" />
        </a>
      </div>
    )
  }
}

export default BackToTop
